//import '../css/styles.css'; CSS Loader is not working with this...
//import 'babel-polyfill';
//import $ from 'jquery';
//import '../css/styles.css';
//import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/styles.css';

import 'babel-polyfill';
import $ from 'jquery';
import 'bootstrap';
import 'slick-carousel';



import Atlas from '../modules/Atlas4/lib/Atlas4.js';
import Carousel from './Carousel.js';
import Options from './Options.js';
import * as urls from './urls.js';

// import maps from './maps.js'

/** GRM Global Variables
 *
 * continents: Stores the networks belonging to each continent
 * metadata: Stores the network metadata by network name
 * debugging: Boolean to indicate whether to log debug messages
 *
 * */
let continents = {};
let metadata = {};

console.log('v2.4.0')

// Sets the default behavior of the menu based on screen width
function setMobileSelect() {

    $(document).ready(function () {

        $('#sidebarCollapse').on('click', function () {
            $('#sidebar, #content').toggleClass('active');
            $('.collapse.in').toggleClass('in');
            $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        });

        var width = $(document).width();

        if (width >= 768) {
            $('#sidebar, #content').toggleClass('active');
            $('.collapse.in').toggleClass('in');
            $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        }
    });
}

// Hides the menu when in mobile views when a network link is clicked
function hideMenuOnClick() {
    if (document.documentElement.clientWidth <= 600) {
        document.getElementById('sidebar').classList.remove('active');
        document.getElementById('content').classList.remove('active');
    }
}

// Function to create the hamburger menu with networks grouped by continents
function addNetworkMenuItem(menuItem, name) {

    let elem = document.getElementById(menuItem);

    var eye = document.createElement('i');
    eye.setAttribute('class', 'fa fa-eye');
    eye.setAttribute('style', 'padding-left: 2px; cursor:pointer;');
    eye.setAttribute('name', name);
    eye.setAttribute('data-name', name); // Network name lookup for onclick to toggle layer

    var a = document.createElement('a');
    a.setAttribute('href', `#?network_name=${name}`);
    a.setAttribute('name', name);
    a.setAttribute('data-name', name);// Network name lookup for onclick to set network view

    let network = metadata[name]
    a.innerHTML = network.alias || name;

    var li = document.createElement('li');
    li.setAttribute('role', 'presentation');
    li.setAttribute('name', name);
    li.appendChild(a);
    li.appendChild(eye);
    li.addEventListener('click', hideMenuOnClick);

    elem.appendChild(li);
    return li;
}

// Generates a color based on str
function stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}


// Function to indicate whether the network is active/inactive. Flips the eye icon in the hamburger menu
function updateIndicators(name) {

    // Menu Indicators
    var menu = document.getElementsByName(name);
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].lastElementChild) {
            // if not active, remove the existing indicator and add the eye-slash icon
            if (!atlas.topologies[name].active) {
                menu[i].lastElementChild.classList.remove('fa-eye');
                menu[i].lastElementChild.classList.add('fa-eye-slash');
            } else {
                menu[i].lastElementChild.classList.remove('fa-eye-slash');
                menu[i].lastElementChild.classList.add('fa-eye');
            }
        }
    }
}


function updateAllIndicators() {
    for (let network in atlas.topologies) {
        updateIndicators(network);
    }
}

// Event handler for the Atlas show all layers button
function showAllNetworks(e) {
    updateAllIndicators();
    setMapStats();
    carousel.play();
    $('.network-container').hide();
    if (window.location.search) {
        let url = window.location.href.split('?')[0];
        window.history.replaceState(null, 'home', url);
    }
    atlas.fitToView()
}

function hideAllNetworks(e) {
    updateAllIndicators();
    setMapStats();
    carousel.pause();
    $('.network-container').hide();
}

// Creates a network card and updates the browser URL
function showNetworkInfo(e) {
    e.preventDefault()
    let target = $(e.currentTarget);
    let name = target.data('name');
    createNetworkCard(name);
    let shareUrl = getShareUrl("network_name", name);
    window.history.replaceState(null, "name", shareUrl);

}

// Creates a network information card for the selected network
function createNetworkCard(name) {

    let network = metadata[name];

    $('.network-title').text(network.alias || name);
    $('.network-body p').text(network.description);

    if (network.logo) {
        $('.network-logo').show();
        $('.network-logo').attr("src", network.logo);
    }
    else {
        $('.network-logo').hide();
    }

    // zoom to selected network
    setNetworkView({ network: name });

    // go to selected network card
    carousel.goTo(name);

    $('.network-footer a').text("Link\xa0to " + (network.alias || name) + "\xa0website");
    $('.network-footer a').attr("href", network.networkURL);
    $('.network-container').show();

    $('#networkClose').click(function () {
        $('.network-container').hide();
    });
}

// Search networks
function searchNetworks() {
    let input = $('#searchNetwork').val().toLowerCase().trim();

    let matches = Object.keys(atlas.topologies).filter((item, pos) => {
        return item.toLowerCase().indexOf(input) > -1;
    });
    $('#searchList').children().remove();
    if (matches.length) {
        matches.forEach(object => {
            if (searchList) {
                let link = addNetworkMenuItem('searchList', object);
                link.firstChild.onclick = showNetworkInfo;
                link.lastChild.onclick = toggleMap;
            }
            updateIndicators(object);
        });
    } else {
        var null_link = document.createElement('a');
        null_link.setAttribute('href', '#');
        null_link.setAttribute('name', 'null');
        null_link.setAttribute('style', 'font-style:italic; opacity:0.5;');
        null_link.innerHTML = 'No matching networks';

        var null_entry = document.createElement('li');
        null_entry.setAttribute('role', 'presentation');
        null_entry.setAttribute('name', 'null');
        null_entry.appendChild(null_link);

        searchList.append(null_entry);
    }

    if (input) {
        $('#continentList').children().not(':first').hide();
        $('#searchList').show();
        $('#continents-btn').text('Networks')
    } else {
        $('#continents-btn').text('Continents')
        $('#continentList').children().not(':first').show();
        $('#searchList').hide();
    }
}

// Set the network stats on the map
function setMapStats() {

    atlas.updateStats();

    if ($('.map-stat')) {
        $('.map-stat').css({ 'opacity': '0', 'transform': 'scale(0.2)' });
    }
    setTimeout(function () {

        for (var stat in atlas.stats) {
            let counter = $(`#map-stats-${stat}-count`);
            let header = $(`#map-stats-${stat}-header`);
            let headend = header.html()[header.html().length - 1];

            counter.html(atlas.stats[stat]);

            // Plural or Singular Header
            if (atlas.stats[stat] === 1 && headend === 's') {
                header.html(header.html().slice(0, -1));
            } else if (atlas.stats[stat] !== 1 && headend !== 's') {
                header.html(header.html() + 's');
            }
        }

        if ($('.map-stat')) {
            $('.map-stat').css({ 'opacity': '1', 'transform': 'scale(1)' });
        }

    }, 250);


}

// Toggles a network's visibility
function toggleNetwork(name) {
    atlas.topologies[name].toggle();
    updateIndicators(name);
    // atlas.fitToView()
}

// Event handler that shows all networks present in a continent
function toggleShowContinent(e) {
    let networks = continents[e.target.name];

    for (let network of networks) {
        if (!atlas.topologies[network].active) {
            toggleNetwork(network);
        }
    }
    setMapStats();
    carousel.play();
}

// Event handler to hide all networks in a continent
function toggleHideContinent(e) {

    let networks = continents[e.target.name];

    for (let network of networks) {
        if (atlas.topologies[network].active) {
            toggleNetwork(network);
        }
    }
    setMapStats();
    carousel.play();
}

// Event handler to turn on/off a network from the network card
function toggleCard(e) {
    let name = e.currentTarget.getAttribute("name");
    let network = name.slice(6);
    toggleNetwork(network);
    setMapStats();
}

// Function to toggle a layer on/off from the hamburger menu
function toggleMap(e) {
    toggleNetwork(e.target.getAttribute("name"));
    setMapStats();
    atlas.fitToView()
}

// Function that constructs the shareable URL given the parameter name and value
function getShareUrl(paramName, paramValue) {
    let url = "";
    if (!window.location.search) {
        url = window.location.href;
    } else {
        url = window.location.href.split('?')[0];
    }
    url += `?map_group=${encodeURIComponent(currentMapGroup)}&${paramName}=${paramValue}`;
    return url;
}

// Event handler that focuses on a selected network from the network card
function setNetworkView(e) {

    let network = e.network || e.currentTarget.getAttribute("name");

    // if current layer is inactive, turn it on and set the indicators
    if (atlas.topologies[network] && !atlas.topologies[network].active) {
        toggleNetwork(network);
    }

    // Turn off all active network menu items except the current and set the indicators
    //atlas.setFocus(network, true);
    atlas._showOnly(network)

    updateAllIndicators();
    setMapStats();
    carousel.pause();

    let bounds = []
    for (const point of atlas.topologies[network].points) {
        bounds.push(point.coord)
    }
    atlas.map.fitBounds(bounds)
}

/**
 * requestMetadata()
 * Gets raw metadata from MongoDB for all networks
 * Used by: requestMetadata()
 * 
 * The raw data returned is an array of objects for each network
 *
 * The raw data includes:
 *   - Name
 *   - Logo
 *   - URL
 *   - Description
 *   - Tags->Continents
 *   - Topology JSON
 *
 * */
async function requestMetadata() {
    try {
        for (const host of maps) {
            let url = host.host
            for (const network of host.networks) {
                let map = await fetch(`${url}name=${network}`)
                map = await map.json()
            }
        }

        // Process the metadata before returning it
        return processMetadata(res.results);
    }
    catch (e) {
        console.error(`Error requesting network information: ${e}`);
    }
}

/**
 * processMetadata()
 * Digests network metadata from MongoDB into the metadata object
 * */
function processMetadata(mapData, dataURL) {
    // Store the metadata fields we want for the network
    let network = {};

    network.alias = mapData?.metadata?.alias
    network.name = mapData.name;
    network.logo = mapData?.metadata?.logo;
    network.url = dataURL;
    network.map = mapData;
    network.continents = mapData?.metadata?.continents;
    network.description = mapData?.metadata?.description;
    network.networkURL = mapData?.metadata?.url || '#';

    // Create the subqueries from the map metadata
    // network.subqueries = {};

    // for (let line of network.map.adjacencies) {
    //     if (line.metadata?.targets) {
    //         for (const target of line.metadata.targets) {
    //             let key = `${target.node_name}+${target.interface_name}`
    //             let value = `(node = "${target.node_name}" and (intf="${target.interface_name}" or alternate_intf="${target.interface_name}"))`
    //             network.subqueries[key] = value
    //         }
    //     }
    // }

    // Store the network in the metadata object
    metadata[network.name] = network;

    // Add the network to continents' arrays
    if (network.continents) {
        for (let continent of network.continents) {
            // Create the array if none exists
            if (!continents[continent]) {
                continents[continent] = [];
            }
            continents[continent].push(network.name);
        }
    }
    console.debug('Finished processing metadata');
    console.debug(metadata);
}


async function requestData(network) {

    fetch(network.url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: network.name,
            start: 'now-15m',
            end: 'now'
        })
    })
        .then(data => data.json())
        .then(data => {
            if (data.status == 200) {
                processData(data.results, network.name)
            } else throw Error(`Circuits for ${network.name} not colored because ${data.status_text}`)
        })
        .catch(e => {
            console.log(e)
        })

}

function processData(results, network) {
    atlas.applyData(results, network)
}

function refreshData(interval) {
    setInterval(function () {
        for (let network in metadata) {
            requestData(metadata[network]);
        }
    }, interval);
}


/**---- MAIN ----**/

// Initialize the Map
let atlas = new Atlas('map', Options);
window.atlas = atlas
atlas.setView({ view: [39.2, -86.5], zoom: 3 });

setMobileSelect();

let urlNetwork = urls.getURLValue('network_name');
if (urlNetwork && urlNetwork.slice(-1) === '#') {
    urlNetwork = urlNetwork.slice(0, -1);
}

// Create the Slick carousel
let carousel = new Carousel('network-carousel', 'carousel-wrapper');
let currentMapGroup

function init() {
    fetch('static/config/maps.json')
        .then(data => data.json())
        .then(config => {
            let maps;
            currentMapGroup = decodeURIComponent(urls.getURLValue('map_group'));


            if (currentMapGroup) maps = config.filter(groups => groups.name == currentMapGroup)

            // Choose the first entry as default map group
            if (!maps || maps.length == 0) {
                maps = config[0].map
                currentMapGroup = config[0].name
            }
            else maps = maps[0].map

            let totalMaps = 0
            let mapsLoaded = 0
            for (const host of maps) {
                totalMaps += host.networks.length
            }

            for (const host of maps) {
                let url = host.host
                for (const map of host.networks) {
                    fetch(`${url}name=${map}`)
                        .then(data => data.json())
                        .then(data => {
                            processMetadata(data[0], host.data)

                            // Get the network's metadata object
                            let network = metadata[data[0].name];

                            // Add the network to each continent dropdown it belongs to
                            if (network.continents) {
                                for (let continent of network.continents) {
                                    let link = addNetworkMenuItem(continent, network.name);
                                    link.firstChild.onclick = showNetworkInfo;
                                    link.lastChild.onclick = toggleMap;
                                }
                            }

                            // Create the carousel slide for the network
                            if (typeof carousel.slides[network.name] == 'undefined') {
                                carousel.addSlide(network, showNetworkInfo);
                            }

                            // Create and set the topology for the network
                            let topology = atlas.addTopology(network.map);
                            requestData(network)
                            mapsLoaded += 1
                            if (mapsLoaded == totalMaps) {
                                wholeMapLoaded(config)
                            }
                        })
                        .catch(err => {
                            mapsLoaded += 1
                            if (mapsLoaded == totalMaps) {
                                wholeMapLoaded()
                            }
                        })
                }
            }

        });

}

function wholeMapLoaded(config) {
    for (let continent in continents) {
        // Bind the show/hide continent functions to their buttons
        let showContinent = document.getElementById(continent + "-show");
        let hideContinent = document.getElementById(continent + "-hide");
        showContinent.onclick = toggleShowContinent;
        hideContinent.onclick = toggleHideContinent;
        let ul = document.getElementById(continent)
        sortList(ul)
    }
    setMapGroups(config)
    updateAllIndicators()
    setMapStats()
    atlas.legends['lines'].show()
    let currentGroup = config.find(group => group.name === currentMapGroup)
    let view = currentGroup.view
    let zoom = currentGroup.zoom
    if (urlNetwork) {
        atlas.setFocus(urlNetwork, true);
        createNetworkCard(urlNetwork);
        carousel.goTo(urlNetwork);
    } else if (currentMapGroup == 'GRM') {
        // Hack for GRM Since GRM should be centered at north america
        let zoom = atlas.map.getZoom()
        atlas.map.setZoom(zoom - 0.5)
        // atlas.map.zoomOut()
    } if (view) {
            zoom = zoom || 1;

            atlas.map.setView(view, zoom);
    } else {
        atlas.fitToView()
    }

}

function setMapGroups(config) {
    let groupList = document.getElementById('map-group-list')
    for (const map of config) {
        let listElem = document.createElement('li')
        let anchorElem = document.createElement('a')
        anchorElem.classList.add('map-group-options')
        anchorElem.setAttribute('href', `./?map_group=${encodeURIComponent(map.name)}`)
        anchorElem.textContent = map.name
        if (map.name == currentMapGroup) anchorElem.classList.add('selected')
        listElem.appendChild(anchorElem)
        groupList.appendChild(listElem)
    }
}

function sortList(ul) {
    var new_ul = ul.cloneNode(false);

    // Add all list to an array
    var lis = [];
    for (var i = ul.childNodes.length; i--;) {
        if (ul.childNodes[i].nodeName === 'LI')
            lis.push(ul.childNodes[i]);
    }
    // Sort the lis in descending order
    lis.sort(function (a, b) {
        return a.textContent.localeCompare(b.textContent);
    });

    // Add them into the ul in order
    for (var i = 0; i < lis.length; i++)
        new_ul.appendChild(lis[i]);
    ul.parentNode.replaceChild(new_ul, ul);
}

init()

$('#searchNetwork').on('keyup', searchNetworks);
$('.show-all-layers').click(showAllNetworks);
$('.hide-all-layers').click(hideAllNetworks);
$('.atlas-toggle-editor').css("display", "none");

// Refresh and update the data, lines, and tooltips every minute
refreshData(60000);
