import $ from 'jquery';

export default class Carousel {

    constructor(id, containerId) {

        this.init        = false;
        this.id          = id;
        this.containerId = containerId;
        this.slides      = {};
        this.index       = 0;

        this.carousel  = $(`#${id}`);
        this.container = $(`#${containerId}`);
        this.container.css('display', 'flex');

        this._setOptions();
    }

    _setOptions() {
        if (!this.init) {
            let options = {
                dots: false,
                autoplay: true,
                autoplaySpeed: 10000,
                speed: 800,
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                lazyLoad: 'ondemand',
                mobileFirst: true,
                centerMode: true,
                focusOnSelect: true,
                centerPadding: '5px'
            };

            this.carousel.slick(options);
        }
    }

    addSlide(network, func) {

        let div = document.createElement('div');
        div.classList.add('card');
        div.classList.add('text-center');
        div.classList.add('m-1');

        let a = document.createElement('a');
        // a.setAttribute('href', `#`);
        a.setAttribute('data-name', network.name);
        a.setAttribute('id', 'networkLogo');
        a.addEventListener('click', (e) => {
            func(e)
        } );
        a.style.cursor = 'pointer'

        let logo;
        if (network.logo) {
            logo = document.createElement('img');
            logo.classList.add('net-logo');
            logo.classList.add('mx-auto');
            logo.setAttribute('alt', network.name);
            logo.setAttribute('src', network.logo);
        }
        else {
            logo = document.createElement('h5');
            logo.classList.add('net-no-logo');
            logo.innerHTML = network.name;
        }
        
        a.appendChild(logo);
        div.appendChild(a);

        this.carousel.slick('slickAdd', div);
        this.slides[network.name] = this.index;
        this.index++;
    }

    goTo(name) {
        if (this.slides[name]) {
            this.carousel.slick('slickGoTo', this.slides[name]);
        }
    }

    pause() {
        this.carousel.slick('slickPause');
    }

    play() {
        this.carousel.slick('slickPlay');
    }
};
