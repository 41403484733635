export function _getURLValue(attr) {
    // First, we load the URL into a variable
    var url = window.location.href;

    // Next, split the url by the ?
    var qparts = url.split("?");

    // Check that there is a querystring, return "" if not
    if (qparts.length == 0 || qparts[1] == null) {
        return null;
    }

    // Then find the querystring, everything after the ?
    var query = qparts[1];

    // Split the query string into variables (separates by &s)
    var vars = query.split("&");

    // Initialize the value with "" as default
    var value = "";

    // Iterate through vars, checking each one for varname
    for (let i=0; i<vars.length; i++) {
        // Split the variable by =, which splits name and value
        var parts = vars[i].split("=");

        // Check if the correct variable
        if (parts[0] == attr) {
            // Load value into variable
            value = parts[1];

            // End the loop
            break;
        }
    }

    // Convert escape code
    value = unescape(value);

    // Convert "+"s to " "s
    value.replace(/\+/g," ");

    // Return the value
    return value;
}

export function validateLat(x){
    if(isNaN(x)){
        return; 
    }
    if(x < -90 || x > 90){
        console.error(x+' is not a valid latitude position, falling back to default');
        return;
    }
    return x;
}

export function validateLng(x){
    if(isNaN(x)){
        return; 
    }
    if(x < -180 || x > 180){
        console.error(x+' is not a valid longitude position, falling back to default');
        return;
    }
    return x;
}

export function getURLValue(param) {
    var value, x;
    switch(param){
        case 'view_lat':
            x = parseFloat(_getURLValue("view_lat"));
            if(validateLat(x) !== undefined){
                value = x;
            }
            break;
        case 'view_lng':
            x = parseFloat(_getURLValue("view_lng"));
            if(validateLng(x) !== undefined){
                value = x;
            }
            break;
        case 'view_alt':
            x = parseFloat(_getURLValue("view_alt"));
            if(!isNaN(x)){
                value = x;
            }
            break;
        case 'network_name':
            x = _getURLValue("network_name");
            if(x !== undefined || x !== null) {
                value = x;
            }
            break;
        case 'map_group':
            x = _getURLValue("map_group");
            if(x !== undefined || x !== null) {
                value = x;
            }
            break;
        default:
            console.error('Do not know how to get url parameter for '+param);
    }
    return value;
}
